import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";

import { getFirestore } from "firebase/firestore";
// Initialize Cloud Firestore and get a reference to the service
// Your web app's Firebase configuration
const firebaseConfig = {
    // for test
    // apiKey: "AIzaSyAKns7YwTutrt_0J7R1FgsdOB1Vqsp-KQw",
    // authDomain: "egsac-carting.firebaseapp.com",
    // projectId: "egsac-carting",
    // storageBucket: "egsac-carting.appspot.com",
    // messagingSenderId: "697401828903",
    // appId: "1:697401828903:web:067ef0e6de1637bec69002"

    // for constantine
    apiKey: "AIzaSyAvFrbSAsuXd3my1-N4HPB_kYj15WxGSUs",
    authDomain: "catring-5d409.firebaseapp.com",
    projectId: "catring-5d409",
    storageBucket: "catring-5d409.appspot.com",
    messagingSenderId: "471908218166",
    appId: "1:471908218166:web:67926b9143108319646f97"
};

let app = initializeApp(firebaseConfig);

const db = getFirestore(app);


const auth = getAuth();

export { auth ,db};
